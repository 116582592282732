import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ErrorCodes } from '@reach/interfaces';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isReachError } from '~app-client/api/utils';
import { QuotaService } from '~app-client/core/services';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';

@Injectable({ providedIn: 'root' })
export class QuotaInterceptor implements HttpInterceptor {
	public static nodesQuotaError = ErrorCodes.QUOTAS_NOT_ENOUGH_NODE_SLOTS_AVAILABLE;
	public static storageQuotaError = ErrorCodes.QUOTAS_NOT_ENOUGH_STORAGE_SLOTS_AVAILABLE;

	private readonly quotaService = inject(QuotaService);
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			tap(
				() => {},
				(event: unknown) => {
					this.handleError(event);
				}
			)
		);
	}

	private async handleError(event: unknown): Promise<void> {
		if (event instanceof HttpErrorResponse && isReachError(event.error)) {
			switch (event.error.code) {
				case ErrorCodes.QUOTAS_NOT_ENOUGH_NODE_SLOTS_AVAILABLE: {
					return this.quotaService.showNodesQuotaExceeded();
				}

				case ErrorCodes.QUOTAS_NOT_ENOUGH_STORAGE_SLOTS_AVAILABLE: {
					return this.quotaService.showStorageQuotaExceeded();
				}

				case ErrorCodes.QUOTAS_NOT_ENOUGH_CHAT_KNOWLEDGE_MESSAGES_SLOTS_AVAILABLE: {
					return this.quotaService.showChatAiQuotaExceeded();
				}
				case ErrorCodes.QUOTAS_NOT_ENOUGH_CHAT_KNOWLEDGE_CONVERSATIONS_SLOTS_AVAILABLE: {
					return this.quotaService.showChatAiConversationsQuotaExceeded();
				}
			}
		}
	}
}
