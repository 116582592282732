export function matrixAverage(a: number[][], axis: 0 | 1, weights?: number[]): number[] {
	const numRows = a.length;
	const numCols = a[0].length;

	if (axis === 1) {
		if (weights && weights.length !== numCols) {
			throw new TypeError(
				'Length of weights must match the number of columns in a for axis 1.'
			);
		}
		return a.map((row) => {
			if (weights) {
				const weightedSum = row.reduce((sum, val, idx) => sum + val * weights[idx], 0);
				const sumWeights = weights.reduce((sum, w) => sum + w, 0);
				if (sumWeights === 0) {
					throw new Error("Weights sum to zero, can't be normalized");
				}
				return weightedSum / sumWeights;
			} else {
				const sum = row.reduce((sum, val) => sum + val, 0);
				return sum / numCols;
			}
		});
	} else if (axis === 0) {
		if (weights && weights.length !== numRows) {
			throw new TypeError('Length of weights must match the number of rows in a for axis 0.');
		}
		const averages = new Array(numCols).fill(0);
		for (let col = 0; col < numCols; col++) {
			let weightedSum = 0;
			let sumWeights = 0;
			for (let row = 0; row < numRows; row++) {
				const weight = weights ? weights[row] : 1;
				weightedSum += a[row][col] * weight;
				sumWeights += weight;
			}
			if (sumWeights === 0) {
				throw new Error("Weights sum to zero, can't be normalized");
			}
			averages[col] = weightedSum / sumWeights;
		}
		return averages;
	} else {
		throw new Error('Invalid axis, only 0 or 1 are allowed.');
	}
}
