<ng-template #tpl>
	<ng-container *transloco="let translate">
		<div
			*ngIf="{
				quota: quota$ | async
			} as vm"
			class="talign-c"
		>
			<div class="w-100 m-1-tb">
				<img src="assets/images/core/robot-error.svg" style="height: 120px" />
				<div class="fs-s fc-warn m-0h-t">
					<span *ngIf="vm.quota">
						{{ vm.quota?.used || 0 }}/{{ vm.quota?.total || 0 }}
					</span>

					<span *ngIf="!vm.quota">&nbsp;</span>
				</div>
			</div>

			<p class="fw-bold fs-icon talign-c">
				{{ translate('errorModals.chat_ai_conversations_quota.title') }}
			</p>

			<p
				class="m-1h-tb"
				[innerHTML]="translate('errorModals.chat_ai_conversations_quota.description')"
			></p>

			<button
				class="w-100 p-0t-tb p-1h-rl border-box bg-primary fw-bold fc-white"
				style="border-radius: calc(0.5 * var(--spacing))"
				(click)="confirm()"
			>
				{{ translate('errorModals.quota_common.check') }}
			</button>

			<button class="w-100 p-0t-tb p-1h-rl border-box fw-bold fc-light" (click)="cancel()">
				{{ translate('general.close') }}
			</button>
		</div>
	</ng-container>
</ng-template>
