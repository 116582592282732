export function vectorNorm(arr: number[], order = 2): number {
	if (order === 1) {
		// Manhattan distance (L1 norm)
		return arr.reduce((acc, val) => acc + Math.abs(val), 0);
	} else if (order === 2) {
		// Euclidean distance (L2 norm)
		return Math.sqrt(arr.reduce((acc, val) => acc + val * val, 0));
	} else {
		// General p-norm
		return Math.pow(
			arr.reduce((acc, val) => acc + Math.pow(Math.abs(val), order), 0),
			1 / order
		);
	}
}
