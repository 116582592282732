import { Injectable } from '@angular/core';
import {
	AskChatKnowledgeResponse,
	ChatKnowledgeChatInterface,
	ChatKnowledgeChatOriginEnum,
	ChatKnowledgeMessageInterface,
	CreateChatKnowledgeChatResponse,
	CreateChatRequest,
	EvaluateChatKnowledgeRequest,
	GetChatKnowledgeMessagesResponse,
	OrderByEnum,
	RequestPaginationInterface,
	UpdateChatRequest,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class ChatAiApiService extends BaseApiService {
	protected commonPath = ['chat-knowledge'];

	public getMessagesByQuestionIds(
		chatId: string,
		questionIds: string[]
	): Promise<GetChatKnowledgeMessagesResponse> {
		const params = {
			messageIds: questionIds,
			t: this.paramTime,
		};
		return this.http.get<GetChatKnowledgeMessagesResponse>(this.url(chatId, 'by-ids'), params);
	}

	public ask(chatId: string, question: string): Promise<AskChatKnowledgeResponse> {
		const body = {
			question,
		};
		this.cleanCache();
		return this.http.post<AskChatKnowledgeResponse>(this.url(chatId, 'ask'), body);
	}

	public load(
		chatId: string,
		options: Required<
			Pick<RequestPaginationInterface<ChatKnowledgeMessageInterface, 'timestamp'>, 'size'>
		> &
			Pick<RequestPaginationInterface<ChatKnowledgeMessageInterface, 'timestamp'>, 'cursor'>
	): Promise<GetChatKnowledgeMessagesResponse> {
		const params = {
			size: `${options.size}`,
			cursor: `${options.cursor}`,
			orderBy: OrderByEnum.DESC,
			t: `${this.cacheTimer}`,
		};
		return this.http.get<GetChatKnowledgeMessagesResponse>(
			this.url(chatId, 'messages'),
			params
		);
	}

	public clearChat(chatId: string): Promise<void> {
		this.cleanCache();
		return this.http.delete<void>(this.url(chatId, 'history'));
	}

	public deleteChat(chatId: string): Promise<void> {
		this.cleanCache();
		return this.http.delete<void>(this.url(chatId, '/'));
	}

	public likeAnswer(chatId: string, answerId: string): Promise<void> {
		const body: EvaluateChatKnowledgeRequest = {
			rating: 1,
			messageId: answerId,
		};
		return this.http.post<void>(this.url(chatId, 'feedback'), body);
	}

	public dislikeAnswer(chatId: string, answerId: string): Promise<void> {
		const body: EvaluateChatKnowledgeRequest = {
			rating: 0,
			messageId: answerId,
		};
		return this.http.post<void>(this.url(chatId, 'feedback'), body);
	}

	public removeReaction(chatId: string, answerId: string): Promise<void> {
		const body: EvaluateChatKnowledgeRequest = {
			rating: null,
			messageId: answerId,
		};
		return this.http.post<void>(this.url(chatId, 'feedback'), body);
	}

	public getChats(origins: ChatKnowledgeChatOriginEnum[]): Promise<ChatKnowledgeChatInterface[]> {
		const params = {
			origins,
		};
		return this.http.get<ChatKnowledgeChatInterface[]>(this.url('/'), params);
	}

	public async createChat(request: CreateChatRequest): Promise<ChatKnowledgeChatInterface> {
		this.cleanCache();
		const response = await this.http.post<CreateChatKnowledgeChatResponse>(
			this.url('/'),
			request
		);
		return response.chat;
	}

	public editChat(
		chatId: string,
		request: UpdateChatRequest
	): Promise<ChatKnowledgeChatInterface> {
		this.cleanCache();
		return this.http.patch<ChatKnowledgeChatInterface>(this.url(chatId, '/'), request);
	}
}
