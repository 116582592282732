import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	NgModule,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { QuotaStatusItem, QuotaTypeEnum } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import { QuotaApiService } from '~app-client/api/services';
import { ReachModalsService } from '~app-client/modals/services';
import { Implicit } from '~app-client/modals/types';

@Component({
	selector: 'chat-ai-conversations-exceeded',
	templateUrl: './chat-ai-conversations-exceeded.component.html',
	styleUrls: ['./chat-ai-conversations-exceeded.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'error-modals' } }],
})
export class ChatAiConversationsExceededComponent implements AfterViewInit {
	@ViewChild('tpl')
	private tpl: TemplateRef<Implicit<void>>;

	private readonly quota$$ = new BehaviorSubject<Pick<QuotaStatusItem, 'total' | 'used'> | null>(
		null
	);
	public readonly quota$ = this.quota$$.asObservable();

	constructor(
		private readonly quotaApi: QuotaApiService,
		private readonly modalsService: ReachModalsService,
		private readonly router: Router
	) {}

	async ngAfterViewInit(): Promise<void> {
		this.modalsService.openModal(this.tpl, null, {
			closeOnOutsideClick: false,
		});

		const quota = await this.quotaApi.getUserQuota();

		const { total, used } = quota[QuotaTypeEnum.CHAT_KNOWLEDGE_CONVERSATIONS];

		if (used > total) {
			this.quota$$.next({ total, used });
		}
	}

	public async confirm(): Promise<void> {
		await this.router.navigate(['/', 'home', 'profile', 'usage']);
		this.cancel();
	}

	public cancel(): void {
		this.modalsService.closeModal();
	}
}

@NgModule({
	declarations: [ChatAiConversationsExceededComponent],
	imports: [CommonModule, TranslocoModule],
	exports: [ChatAiConversationsExceededComponent],
})
export class ChatAiConversationsExceededModule {}
